


































import { Vue, Component } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import CourseCard from "./CourseCard.vue";
// eslint-disable-next-line no-unused-vars
import { CatalogItem } from "@/types";

@Component({
  components: {
    CourseCard
  }
})
export default class extends Vue {
  items: CatalogItem[] = [];

  getCourses(): void {
    this.items = [];

    firebase
      .firestore()
      .collection("courses")
      .where("disabled", "==", false)
      .orderBy("name", "asc")
      .get()
      .then(query => {
        query.forEach(doc => {
          const newItem: CatalogItem = {
            id: doc.id,
            code: doc.data().code,
            urlCode: doc.data().urlCode,
            name: doc.data().name,
            color: doc.data().color,
            img:
              doc.data().img !== ""
                ? doc.data().previewImg
                : require("@/assets/img/course-preview-default.jpg")
          };

          this.items.push(newItem);
        });
      });
  }

  created(): void {
    this.getCourses();
  }
}
